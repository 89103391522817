<template>
  <div>
    <breadcrumb-nav>
      <template v-slot:firstMenu>任务管理</template>
      <template v-slot:secondMenu>分享配置</template>
    </breadcrumb-nav>
    <el-card>
    <el-button
      type="primary"
      icon="el-icon-plus"
      style="margin: 10px 0px"
      @click="showDialog"
    >添加
    </el-button>
    <el-table style="width: 100%" border :data="list">
      <el-table-column type="index" label="序号" width="80px" align="center">
      </el-table-column>
      <el-table-column prop="imageUrl" label="图标" width="100px">
        <template slot-scope="{ row }">
          <img :src="row.imageUrl" alt="" style="width: 50px; height: 50px" />
        </template>
      </el-table-column>
      <el-table-column prop="bigImageUrl" label="大图" width="100px">
        <template slot-scope="{ row }">
          <img :src="row.bigImageUrl" alt="" style="width: 50px; height: 50px" />
        </template>
      </el-table-column>
      <el-table-column prop="title" label="标题" width="100px">
      </el-table-column>
      <el-table-column prop="desc" label="描述" width="150px">
      </el-table-column>
      <el-table-column prop="title" label="分享场景" width="100px">
        <template slot-scope="scope">{{ scope.row.sence | senceLabel }}</template>
      </el-table-column>
      <el-table-column label="生效时间" prop="start_time" :formatter="formatStartTime">
      </el-table-column>
      <el-table-column label="失效时间" prop="end_time" :formatter="formatEndTime">
      </el-table-column>
      <el-table-column prop="state" label="状态" width="170px">
        <template slot-scope="{row}">
          <el-switch v-model="row.status" active-text="生效" inactive-text="失效" :active-value=1
                     :inactive-value=2 @change="changeStatus(row)">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="prop" label="操作" width="200px;">
        <template slot-scope="{row}">
          <el-row>
            <el-button
              type="warning"
              icon="el-icon-edit"
              size="mini"
              @click="updateShareConf(row)"
            >修改</el-button
            >
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="delShareConf(row)"
            >删除</el-button
            >
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :title="form.id ? '修改分享配置' : '添加分享配置'"
      :visible.sync="dialogFormVisible"
    >
      <el-form style="width: 80%" :model="form" :rules="rules" ref="form">
        <el-form-item label="标题" label-width="100px" prop="title">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="描述" label-width="100px" prop="desc">
          <el-input v-model="form.desc"></el-input>
        </el-form-item>
        <el-form-item label="生效时间" label-width="100px" prop="startTime">
          <el-date-picker style="width: 100%"
            v-model="form.startTime"
            type="datetime" value-format="timestamp"
            placeholder="选择生效时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="失效时间" label-width="100px" prop="endTime">
          <el-date-picker style="width: 100%"
            v-model="form.endTime"
            type="datetime" value-format="timestamp"
            placeholder="选择失效时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="类型" label-width="100px" prop="sence">
          <el-select v-model="form.sence" style="width:100%" placeholder="请选择显示场景">
            <el-option
              v-for="item in senceTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="图标" label-width="100px" prop="icon">
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="handleUploadSuccess"
            :headers="imgUploadHeaders"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="大图" label-width="100px" prop="bigIcon">
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="handleUploadBigIconSuccess"
            :headers="imgUploadHeaders"
          >
            <img v-if="bigImageUrl" :src="bigImageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addOrUpdateShareConf"
        >确 定</el-button
        >
      </div>
    </el-dialog>
    </el-card>
  </div>
</template>

<script>
import BreadcrumbNav from '../common/BreadcrumbNav'
import { updateShareConfStatusRequest, getShareConfListRequest, delShareConfRequest, addShareConfRequest, updateShareConfRequest } from '../../network/share'
export default {
  name: 'Share',
  components: {
    BreadcrumbNav
  },
  filters: {
    senceLabel (value) {
      switch (value) {
        case 1:
          return '邀请'
        case 2:
          return '分享商品'
        default:
          return '广告失败'
      }
    }
  },
  data () {
    return {
      // 总共数据条数
      total: 0,
      // 列表展示的数据
      list: [],
      // 对话框显示与隐藏控制的属性
      dialogFormVisible: false,
      uploadUrl: window.g.MEDIA_URL + '/upload',
      imgUploadHeaders: { // 上传图片控件的header
        Authorization: sessionStorage.getItem('token')
      },
      imageUrl: '',
      bigImageUrl: '',
      // 收集签到规则信息
      form: {
        icon: '',
        title: '',
        desc: '',
        startTime: '',
        endTime: '',
        sence: '',
        bigIcon: ''
      },
      // 表单验证规则
      rules: {
        title: [{ required: true, message: '请输入分享标题', trigger: 'blur' }],
        desc: [{ required: true, message: '请输入分享描述', trigger: 'blur' }],
        icon: [{ required: true, message: '请选择分享图标' }],
        sence: [{ required: true, message: '请选择显示场景', trigger: 'change' }],
        bigIcon: [{ required: true, message: '请选择分享大图' }]
      },
      senceTypes: [
        {
          label: '邀请',
          value: 1
        },
        {
          label: '分享商品',
          value: 2
        }
      ]
    }
  },
  // 组件挂载完毕发请求
  mounted () {
    // 获取列表数据方法
    this.getShareConfList()
  },
  methods: {
    getShareConfList () {
      getShareConfListRequest().then(res => {
        const result = res.data
        console.log('share conf list:', result.data.list)
        if (res.status !== 200) {
          return this.alertMessage('获取分享配置列表失败', 'error')
        }
        this.list = result.data.list
        for (const item of this.list) {
          item.imageUrl = window.g.MEDIA_DOWNLOAD_URL + item.icon
          item.bigImageUrl = window.g.MEDIA_DOWNLOAD_URL + item.bigIcon
        }
        this.total = result.data.total
      })
    },
    updateShareConf (row) {
      console.log('update row:', row)
      this.dialogFormVisible = true
      this.form = { ...row }
      this.imageUrl = window.g.MEDIA_DOWNLOAD_URL + this.form.icon
      this.bigImageUrl = window.g.MEDIA_DOWNLOAD_URL + this.form.bigIcon
    },
    delShareConf (row) {
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delShareConfRequest(row.id).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('删除失败', 'error')
          }
          this.alertMessage('删除成功', 'success')
          this.getShareConfList()
        })
      }).catch(() => {
        this.alertMessage('已取消删除', 'info')
      })
    },
    showDialog () {
      this.dialogFormVisible = true
      this.form = { icon: '', title: '', desc: '', startTime: '', endTime: '', bigIcon: '', sence: '' }
      this.imageUrl = ''
      this.bigImageUrl = ''
    },
    updateShareConf2Srv () {
      updateShareConfRequest(this.form).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('修改分享配置失败', 'error')
        }
        this.alertMessage('修改分享配置成功', 'success')
        this.getShareConfList()
      })
    },
    addShareConf2Srv () {
      addShareConfRequest(this.form).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('添加分享配置失败', 'error')
        }
        this.alertMessage('添加分享配置成功', 'success')
        this.getShareConfList()
      })
    },
    addOrUpdateShareConf () {
      this.$refs.form.validate(valid => {
        if (!valid) return
        if (this.form.startTime === '') {
          this.form.startTime = 0
        }
        if (this.form.endTime === '') {
          this.form.endTime = 0
        }
        if (this.form.endTime !== 0 && this.form.endTime < this.form.startTime) {
          return this.alertMessage('失效时间不能小于生效时间', 'error')
        }
        this.dialogFormVisible = false
        console.log('share conf form:', this.form)
        if (this.form.id) {
          this.updateShareConf2Srv()
        } else {
          this.addShareConf2Srv()
        }
      })
    },
    handleUploadSuccess (res, file) {
      console.log('handleUploadSuccess res:', res)
      this.form.icon = res.data.list[0].mediaId
      this.imageUrl = window.g.MEDIA_DOWNLOAD_URL + this.form.icon
    },
    handleUploadBigIconSuccess (res, file) {
      this.form.bigIcon = res.data.list[0].mediaId
      this.bigImageUrl = window.g.MEDIA_DOWNLOAD_URL + this.form.bigIcon
      console.log('handleUploadBigIconSuccess big:', this.form.bigIcon)
    },
    changeStatus (row) {
      updateShareConfStatusRequest({ id: row.id, status: row.status }).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('修改分享配置状态失败', 'error')
        }
        this.alertMessage('修改分享配置状态成功', 'success')
        this.getShareConfList()
      })
    },
    formatStartTime (row) {
      return this.formatDate(row.startTime)
    },
    formatEndTime (row) {
      return this.formatDate(row.endTime)
    },
    formatDate (value) {
      if (value === 0) {
        return '---'
      }
      const date = new Date(value)
      const year = date.getFullYear()
      const month = (date.getMonth() + 1 + '').padStart(2, '0')
      const day = (date.getDate() + '').padStart(2, '0')
      if (this.curReqType === 1) {
        return `${year}-${month}-${day}`
      } else {
        const hour = (date.getHours() + '').padStart(2, '0')
        const minute = (date.getMinutes() + '').padStart(2, '0')
        const second = (date.getSeconds() + '').padStart(2, '0')
        return `${year}-${month}-${day} ${hour}:${minute}:${second}`
      }
    }
  }
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
