import { request } from './request'

// 获取分享配置列表
export function getShareConfListRequest (params) {
  return request({
    baseUrl: window.g.TASK_URL,
    url: '/share/conf/list',
    method: 'get',
    params
  })
}

// 更新通知配置
export function updateShareConfRequest (info) {
  return request({
    baseUrl: window.g.TASK_URL,
    url: '/share/conf/update',
    method: 'post',
    data: { ...info }
  })
}

// 更新通知配置状态
export function updateShareConfStatusRequest (info) {
  return request({
    baseUrl: window.g.TASK_URL,
    url: '/share/conf/update/status',
    method: 'post',
    data: { ...info }
  })
}

// 添加通知配置
export function addShareConfRequest (info) {
  return request({
    baseUrl: window.g.TASK_URL,
    url: '/share/conf/add',
    method: 'post',
    data: { ...info }
  })
}

// 删除任务
export function delShareConfRequest (id) {
  return request({
    baseUrl: window.g.TASK_URL,
    url: `/share/conf/${id}`,
    method: 'delete'
  })
}
